import { Component, OnInit } from '@angular/core';
import { SITE_SETTINGS } from './settings';
import firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { TaxiService } from './taxi.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  settings = SITE_SETTINGS;
  cars;

  constructor(private taxiService: TaxiService) {}
  
  ngOnInit() {

    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebaseConfig);
    }
    if (this.settings.isCMS) {
      this.getTariff()
    }

  }


  getTariff() {
    firebase.database().ref('tariff').on("value", snap => {
      let data = snap.val();
      let tariff = [];
      if (data) {
        Object.keys(data).forEach(key => {
          if (data[key]) {
            data[key]['keyID'] = key;
            tariff.push(data[key]);
          }
        });
        this.cars = tariff;
        this.taxiService.tariff.next(this.cars);
      }
    });
  }

}
