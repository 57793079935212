import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaxiService {

  routeDetails = new BehaviorSubject(null);
  rideDetails = new BehaviorSubject(null);
  userDetails = new BehaviorSubject(null);
  tariff = new BehaviorSubject(null);

  constructor() { }
}
