export const tamilnadu_districts = [
    'Ariyalur',
    'Chengalpattu',
    'Chennai',
    'Coimbatore',
    'Cuddalore',
    'Dharmapuri',
    'Dindigul',
    'Erode',
    'Kallakurichi',
    'Kanchipuram',
    'Kanyakumari',
    'Karur',
    'Krishnagiri',
    'Madurai',
    'Nagapattinam',
    'Namakkal',
    'Nilgiris',
    'Perambalur',
    'Pudukkottai',
    'Ramanathapuram',
    'Ranipet',
    'Salem',
    'Sivaganga',
    'Tenkasi',
    'Thanjavur',
    'Theni',
    'Thoothukudi',
    'Tiruchirappalli',
    'Tirunelveli',
    'Tirupathur',
    'Tiruppur',
    'Tiruvallur',
    'Tiruvannamalai',
    'Tiruvarur',
    'Vellore',
    'Viluppuram',
    'Virudhunagar',
    'Bangalore',
    'Pondicherry',
    'Tirupati',
 ];
 
 export const outstation_citis = [
    'Chengalpattu',
    'Coimbatore',
    'Kanchipuram',
    'Madurai',
    'Vellore',
    'Tiruvannamalai',
    'Salem',
    'Salem',
    'Trichy',
    'Ariyalur',
    'Cuddalore',
    'Dharmapuri',
    'Dindigul',
    'Erode',
    'Kallakurichi',
    'Kanyakumari',
    'Karur',
    'Krishnagiri',
    'Mayiladuthurai',
    'Nagapattinam',
    'Namakkal',
    'Nilgiris',
    'Perambalur',
    'Pudukkottai',
    'Ramanathapuram',
    'Ranipet',
    'Sivagangai',
    'Tenkasi',
    'Thanjavur',
    'Theni',
    'Thoothukudi',
    'Tirupattur',
    'Tiruppur',
    'Tiruvallur',
    'Tiruvarur',
    'Viluppuram',
    'Virudhunagar',
    'Bangalore',
    'Pondicherry',
    'Tirupati',
 ]
 
 export const popular_cities = ['Chennai', 'Madurai', 'Tiruchirappalli', 'Coimbatore', 'Tiruppur'];
 
 // export const popular_outstation_routes = [
 //    {
 //       origin: 'Chennai',
 //       destination: 'Coimbatore',
 //    },
 //    {
 //       origin: 'Chennai',
 //       destination: 'Madurai',
 //    },
 //    {
 //       origin: 'Chennai',
 //       destination: 'Vellore',
 //    },
 //    {
 //       origin: 'Chennai',
 //       destination: 'Tiruvannamalai',
 //    },
 //    {
 //       origin: 'Chennai',
 //       destination: 'Bangalore',
 //    },
 //    // {
 //    //    origin: 'Madurai',
 //    //    destination: 'Ramanadhapuram',
 //    // },
 //    // {
 //    //    origin: 'Tirunelveli',
 //    //    destination: 'Chennai',
 //    // },
 //    // {
 //    //    origin: 'Salem',
 //    //    destination: 'Tiruchirapalli',
 //    // },
 //    // {
 //    //    origin: 'Coimbatore',
 //    //    destination: 'Bangalore',
 //    // },
 //    // {
 //    //    origin: 'Chennai',
 //    //    destination: 'Erode',
 //    // },
 
 // ];
 
 // export const popular_outstation_other_city_routes = [
 //    {
 //       origin: 'Madurai',
 //       destination: 'Ramanadhapuram',
 //    },
 //    {
 //       origin: 'Tirunelveli',
 //       destination: 'Chennai',
 //    },
 //    {
 //       origin: 'Salem',
 //       destination: 'Tiruchirapalli',
 //    },
 //    {
 //       origin: 'Coimbatore',
 //       destination: 'Bangalore',
 //    },
 //    // {
 //    //    origin: 'Erode',
 //    //    destination: 'Chennai',
 //    // },
 //    {
 //       origin: 'Pondicherry',
 //       destination: 'Bangalore',
 //    }
 // ];
 
 export const PLACES = [
    {
       city: 'Chennai',
       routes: [
          {
             origin: 'Chennai',
             destination: 'Bangalore',
             image: 'https://www.track2realty.track2media.com/wp-content/uploads/2016/02/Bangalore-City.jpg',
             carType: 'Sedan',
             distance: '346.7 km',
             duration: '5 hr 52 min',
             estimation: '4461',
             url: 'chennai-to-bengaluru-outstation-drop-taxi',
          },
          {
             origin: 'Chennai',
             destination: 'Pondicherry',
             image: 'https://img.traveltriangle.com/blog/wp-content/uploads/2020/01/cover-Pondicherry-travel_8th-Jan.jpg',
             carType: 'Sedan',
             distance: '150.7 km',
             duration: '2 hr 36 min',
             estimation: '2112',
             url: 'chennai-to-puducherry-outstation-drop-taxi'
          },
          {
             origin: 'Chennai',
             destination: 'Madurai',
             image: 'https://img.veenaworld.com/wp-content/uploads/2020/11/10-Madurai-Temples-with-Brilliant-Architecture-800x533.jpg?imwidth=1080',
             carType: 'Sedan',
             distance: '462.3 km',
             duration: '7 hr 6 min',
             estimation: '5856',
             url: 'chennai-to-madurai-outstation-drop-taxi'
          },
          {
             origin: 'Chennai',
             destination: 'Trichy',
             image: 'https://images.saymedia-content.com/.image/t_share/MTc4MjMzNDY3NDA5Mjc4NTcz/beautiful-places-to-visit-near-trichy.jpg',
             carType: 'Sedan',
             distance: '331.8 km',
             duration: '5 hr 8 min',
             estimation: '4284',
             url: 'chennai-to-tiruchirappalli-outstation-drop-taxi'
          },
          {
             origin: 'Chennai',
             destination: 'Coimbatore',
             image: 'https://www.tusktravel.com/blog/wp-content/uploads/2020/01/Isha-Yoga-Centre-Coimbatore-1.jpg',
             carType: 'Sedan',
             distance: '505.5 km',
             duration: '8 hr 14 min',
             estimation: '6372',
             url: 'chennai-to-coimbatore-outstation-drop-taxi'
          }
       ]
    },
    {
       city: 'Bangalore',
       routes: [
          {
             origin: 'Bangalore',
             destination: 'Chennai',
             image: 'https://www.track2realty.track2media.com/wp-content/uploads/2016/02/Bangalore-City.jpg',
             carType: 'Sedan',
             distance: '347.2 km',
             duration: '6 hr 8 min',
             estimation: '4476',
             url: 'bengaluru-to-chennai-outstation-drop-taxi'
          },
          {
             origin: 'Bangalore',
             destination: 'Pondicherry',
             image: 'https://img.traveltriangle.com/blog/wp-content/uploads/2020/01/cover-Pondicherry-travel_8th-Jan.jpg',
             carType: 'Sedan',
             distance: '310.0 km',
             duration: '7 hr 5 min',
             estimation: '4020',
             url: 'bengaluru-to-puducherry-outstation-drop-taxi'
          },
          {
             origin: 'Bangalore',
             destination: 'Madurai',
             image: 'https://img.veenaworld.com/wp-content/uploads/2020/11/10-Madurai-Temples-with-Brilliant-Architecture-800x533.jpg?imwidth=1080',
             carType: 'Sedan',
             distance: '435.9 km',
             duration: '7 hr 34 min',
             estimation: 5530,
             url: 'bengaluru-to-madurai-outstation-drop-taxi'
          },
          {
             origin: 'Bangalore',
             destination: 'Trichy',
             image: 'https://images.saymedia-content.com/.image/t_share/MTc4MjMzNDY3NDA5Mjc4NTcz/beautiful-places-to-visit-near-trichy.jpg',
             carType: 'Sedan',
             distance: '344.0 km',
             duration: '6 hr 20 min',
             estimation: 12 * parseFloat('344.0 km') + 300,
             url: 'bengaluru-to-trichy-outstation-drop-taxi'
          },
          {
             origin: 'Bangalore',
             destination: 'Coimbatore',
             image: 'https://www.tusktravel.com/blog/wp-content/uploads/2020/01/Isha-Yoga-Centre-Coimbatore-1.jpg',
             carType: 'Sedan',
             distance: '364.5 km',
             duration: '6 hr 34 min',
             estimation: 12 * parseFloat('364.5 km') + 300,
             url: 'bengaluru-to-coimbatore-outstation-drop-taxi'
          }
       ]
    },
    {
       city: 'Pondicherry',
       routes: [
          {
             origin: 'Pondicherry',
             destination: 'Chennai',
             image: 'https://www.nationsonline.org/gallery/India/Chennai-High-Court.jpg',
             carType: 'Sedan',
             distance: '150.9 km',
             duration: '2 hr 41 min',
             estimation: 12 * parseFloat('150.9 km') + 300,
             url: 'pondicherry-to-chennai-outstation-drop-taxi'
          },
          {
             origin: 'Pondicherry',
             destination: 'Bangalore',
             image: 'https://www.track2realty.track2media.com/wp-content/uploads/2016/02/Bangalore-City.jpg',
             carType: 'Sedan',
             distance: '308.6 km',
             duration: '6 hr 40 min ',
             estimation: 4005,
             url: 'pondicherry-to-bangalore-outstation-drop-taxi'
          },
          {
             origin: 'Pondicherry',
             destination: 'Madurai',
             image: 'https://cdn1.goibibo.com/voy_ing/t_fs/tamil-nadu-madurai-14934407517o.jpeg',
             carType: 'Sedan',
             distance: '334.4 km',
             duration: '5 hr 32 min',
             estimation: 4312,
             url: 'pondicherry-to-madurai-outstation-drop-taxi'
          },
          {
             origin: 'Pondicherry',
             destination: 'Trichy',
             image: 'https://res.cloudinary.com/enchanting/f_auto,q_70/et-web/2015/05/Enchanting-Travels-India-Tours-South-India-Tours-Trichy.jpg',
             carType: 'Sedan',
             distance: '203.9 km',
             duration: '3 hr 26 min',
             estimation: 12 * parseFloat('203.9 km') + 300,
             url: 'pondicherry-to-trichy-outstation-drop-taxi'
          },
          {
             origin: 'Pondicherry',
             destination: 'Coimbatore',
             image: 'https://img.traveltriangle.com/blog/wp-content/uploads/2017/10/Cover27.jpg',
             carType: 'Sedan',
             distance: '377.6 km',
             duration: '6 hr 39 min',
             estimation: 4831,
             url: 'pondicherry-to-coimbatore-outstation-drop-taxi'
          }
       ]
    },
    {
       city: 'Coimbatore',
       routes: [
          {
             origin: 'Coimbatore',
             destination: 'Chennai',
             image: 'https://img.traveltriangle.com/blog/wp-content/uploads/2017/10/Cover27.jpg',
             carType: 'Sedan',
             distance: '506.6 km',
             duration: '8 hr 37 min',
             estimation: 6400,
             url: 'coimbatore-to-chennai-outstation-drop-taxi'
          },
          {
             origin: 'Coimbatore',
             destination: 'Bangalore',
             image: 'https://lp-cms-production.imgix.net/2019-06/9483508eeee2b78a7356a15ed9c337a1-bengaluru-bangalore.jpg',
             carType: 'Sedan',
             distance: '363.5 km',
             duration: '6 hr 24 min',
             estimation: 12 * parseFloat('363.5 km') + 300,
             url: 'coimbatore-to-bangalore-outstation-drop-taxi'
          },
          {
             origin: 'Coimbatore',
             destination: 'Madurai',
             image: 'https://cdn1.goibibo.com/voy_ing/t_fs/tamil-nadu-madurai-14934407517o.jpeg',
             carType: 'Sedan',
             distance: '216.1 km',
             duration: '4 hr 3 min',
             estimation: 12 * parseFloat('216.1 km') + 300,
             url: 'coimbatore-to-madurai-outstation-drop-taxi'
          },
          {
             origin: 'Coimbatore',
             destination: 'Trichy',
             image: 'https://res.cloudinary.com/enchanting/f_auto,q_70/et-web/2015/05/Enchanting-Travels-India-Tours-South-India-Tours-Trichy.jpg',
             carType: 'Sedan',
             distance: '216.9 km',
             duration: '4 hr 17 min',
             estimation: 12 * parseFloat('216.9 km') + 300,
             url: 'coimbatore-to-trichy-outstation-drop-taxi'
          },
          {
             origin: 'Coimbatore',
             destination: 'Pondicherry',
             image: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/11/f4/3b/43/boating.jpg?w=1000&h=600&s=1',
             carType: 'Sedan',
             distance: '378.1 km',
             duration: '6 hr 35 min',
             estimation: 4900,
             url: 'coimbatore-to-pondicherry-outstation-drop-taxi'
          }
       ]
    }
 ]
 
 export const airport_citis = [
    'Chennai',
    'Coimbatore',
    'Madurai',
    'Tiruchirapalli',
    'Tuticorin',
    'Salem',
    'Puducherry',
    'Bangalore'
 ]
 
 
 export const popular_city = [
    {
       'city': 'Vellore',
       'image': 'assets/vellor.png'
    },
    {
       'city': 'Chennai',
       'image': 'assets/circle-chennai.png'
    },
    {
       'city': 'Coimbatore',
       'image': 'assets/circle-coi.png'
    },
    {
       'city': 'Madurai',
       'image': 'assets/circle-m.png'
    },
    {
       'city': 'Trichy',
       'image': 'assets/trichy.png'
    },
    {
       'city': 'Pondicherry',
       'image': 'assets/pondi.png'
    },
    {
       'city': 'Bangalore',
       'image': 'assets/bang.png'
    }
 ]
 
 
 