export const CARS = [
    {
      carType: 'Sedan',
      'oneway': 13,
      'roundtrip': 12,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 400,
      availableCars: 'SWIFT, ETIOS, XCENT',
      passengers: 4,
      image: '/assets/car-zest.png'
    },
    {
      carType: 'Etios',
      'oneway': 14,
      'roundtrip': 12,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 400,
      availableCars: ' ETIOS',
      passengers: 4,
      image: '/assets/etios.png'
    },
    {
      carType: 'Suv',
      'oneway': 18,
      'roundtrip': 16,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 400,
      availableCars: 'INNOVA, XYLO',
      passengers: 6,
      image: '/assets/suv.png'
    },
    // {
    //   carType: 'Ertiga',
    //   'oneway': 16,
    //   'roundtrip': 14,
    //   'onewayDriverFee': 500,
    //   'roundTripDriverFee': 500,
    //   availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
    //   passengers: 5,
    //   image: '/assets/ertiga.jpeg'
    // },
    {
      carType: 'Innova',
      'oneway': 19,
      'roundtrip': 17,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 500,
      availableCars: 'INNOVA',
      passengers: 6,
      image: '/assets/innova.png'
    },
    // {
    //   carType: 'Crysta',
    //   'oneway': 26,
    //   'roundtrip': 16,
    //   'onewayDriverFee': 500,
    //   'roundTripDriverFee': 500,
    //   availableCars: 'INNOVA',
    //   passengers: 7,
    //   image: '/assets/innova-crysta.png'
    // },
    // {
    //   carType: 'Tempo Traveller',
    //   'oneway': 28,
    //   'roundtrip': 28,
    //   'onewayDriverFee': 500,
    //   'roundTripDriverFee': 500,
    //   availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
    //   passengers: 12,
    //   image: '/assets/traveller.png'
    // },
  ];