import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CARS } from 'src/app/cars';
import { SITE_SETTINGS } from 'src/app/settings';
import { TaxiService } from 'src/app/taxi.service';

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.css']
})
export class TariffComponent implements OnInit {

  settings = SITE_SETTINGS;
  cars;
  constructor(private router: Router, private taxiService: TaxiService  ) { }

  ngOnInit(): void {
    this.taxiService.tariff.subscribe(res => {
      if(res) {
        this.cars = res;
      }
    })

  }

  back() {
    this.router.navigateByUrl('/');
  }
}
