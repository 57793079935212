<a routerLink="/">
    <h3>
        <img src="assets/left-arrow.png">Outstation Drop Taxi Routes
    </h3>
</a>


<div class="container">
    <section class="muse-section pb-4 pb-md-5">
        <div class="row align-items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-8">
                <p class="big lh-lg text-black-600">Outstation one way and Round trip cab routes accross Tamilnadi, Bangalore, Pondicherry and Kerala.</p>
            </div>
        </div>
        <div class="row pt-4">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let city of places">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          Outstation cabs in {{city}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container *ngFor="let subCity of places">
                        <h6 class="city" *ngIf="city !== subCity">
                            <a class="text-black-600 caption font-weight-semibold" (click)="selectedCity(city, subCity )" routerLink="/city/{{city | lowercase}}-to-{{subCity | lowercase}}-outstation-cabs"> {{city}} to {{subCity}} One Way Cabs</a>
                        </h6>
                    </ng-container>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </section>
</div>