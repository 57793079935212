<a routerLink="/city">
    <h3>
        <img src="assets/left-arrow.png">Our Outstation Tariff
    </h3>
</a>



<div class="container">

    <section class="muse-section" style="padding-bottom: 200px;">
        <div class="row align-items-center">

            <div *ngFor="let car of cars" class="col-md-4">

                <div class="align-items-center ">
                    <div class="align-items-center ">
                        <img class="img img-raised car-image" src="{{car.tariffImage}}" style="width: 100%;">

                        <div>
                            <h6 class="font-weight-normal ">{{car.carType}}
                            </h6>
                            <small class="d-block text-gray-600 ">
                                ONE WAY : <span class="badge badge-primary fs-11 py-1 px-2 ml-1">
                                    ₹{{car.oneway}} / km </span> <br>
                                ROUND TRIP : <span class="badge badge-primary fs-11 py-1 px-2 ml-1">
                                    ₹{{car.roundtrip}} / km</span> <br>
                                AVAILABLE CARS: <span class="fs-11 py-1 px-2 ml-1">
                                    {{car.availableCars}}</span> <br>
                            </small>
                        </div>
                    </div>
                    <div class="ml-auto dropdown ">
                        <a routerLink="/" class="btn btn-warning">Book Now</a>
                    </div>
                </div>



            </div>
        </div>

        <div>
            <h3>ADDITIONAL CHARGES DETAILS</h3>

            Toll Fees, Inter-State Permit charges (if any) are extra. <br>

            SEDAN - Driver bata is Rs. 300/- per day [Rs. 600 for above 400kms].<br>

            SUV - Driver bata is Rs. 400/- per day [Rs. 600 for above 400kms].<br>

            Hill Station Charges - SEDAN - Rs. 300 SUV - Rs. 500<br>

            Round Trips - Minimum running must be 250kms per day. For Bengaluru it is minimum 300kms per day.<br>

            Drop Trips - Minimum running must be 130 kms per day.<br>

            1 day means 1 Calender day (from midnight 12 to Next Midnight 12)<br>

            Waiting Charges will be Rs.150 per hour.(Except 30 min for food.)<br>

            Max lagguage capacity by vehicle type - Sedan - 3 suitcases, Suv - 4 suitcases<br>

            <br>
            You can cancel your DropTaxi booking 24 hours before your journey, but after that, a cancellation fee of 300
            to 500 Rs applies.

            <br>
            <strong> Note : Normal days the charges will be normal .At festival season Charge will be changed to Special
                tariff,
            </strong>
        </div>
    </section>
</div>