import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { AllRoutesComponent } from './pages/all-routes/all-routes.component';
import { CityDetailComponent } from './pages/city-detail/city-detail.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { TariffComponent } from './pages/tariff/tariff.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'city',
    component: AllRoutesComponent
  },
  {
    path: 'city/:url',
    component: CityDetailComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'tariff',
    component: TariffComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
