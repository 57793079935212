import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tamilnadu_districts } from 'src/app/places';

@Component({
  selector: 'app-all-routes',
  templateUrl: './all-routes.component.html',
  styleUrls: ['./all-routes.component.css']
})
export class AllRoutesComponent implements OnInit {

  places = tamilnadu_districts;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  back() {
    this.router.navigateByUrl('/');
  }

  selectedCity(origin, destination) {
  //  this.outstationService.routeDetails.next({'origin': origin, 'destination': destination});
  }


}
