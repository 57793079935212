import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CanonicalService } from 'src/app/canonical.service';
import { CARS } from 'src/app/cars';
import { OutstationService } from 'src/app/outstation.service';
import { PLACES } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/settings';
import { TaxiService } from 'src/app/taxi.service';

@Component({
  selector: 'app-city-detail',
  templateUrl: './city-detail.component.html',
  styleUrls: ['./city-detail.component.css']
})
export class CityDetailComponent implements OnInit {

  origin;
  destination;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  totalDistance;
  totalDuration;
  showDirection;
  cars = CARS;
  estimations = [];
  settings = SITE_SETTINGS;
  title;
  routeDetails;
  showEstimation = false;
  places = PLACES;
  otherCities;
  hideSpinner = false;
  constructor(private outstationService: OutstationService, private activeRoute: ActivatedRoute, private canonicalService: CanonicalService, private titleService: Title, private metaTagService: Meta, private taxiService: TaxiService, private router: Router
  ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.outstationService.hideBackBtn.next(true);
    let routeUrl = this.activeRoute.snapshot.paramMap.get('url').split('-');
    this.origin = routeUrl[0].charAt(0).toUpperCase() + routeUrl[0].slice(1);
    this.destination = routeUrl[2].charAt(0).toUpperCase() + routeUrl[2].slice(1);

    (this.origin && this.destination) ? this.showDirection = true : this.showDirection = false;

    this.title = `Book ${this.origin} to ${this.destination} Outstation Cabs at just 9₹ per km | ${this.settings.siteName}`;
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: `Book ${this.origin} to ${this.destination} Outstation Cabs at just 9₹ per km. Choose from a range of ${this.origin} To ${this.destination} cabs - round trips, and one-way cabs` }
    );

    this.otherCities = this.places.filter(city => city.city.toLowerCase() === this.origin);

    console.log(this.otherCities);
  }

  back() {
    this.router.navigateByUrl('/');
  }

  onResponses(event) {
    this.hideSpinner = true;
    this.routeDetails = {
      'origin': this.origin,
      'destination': this.destination,
      'totalDistance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, ""),
      'totalDuration': event.routes[0].legs[0].duration.text
    }
    console.log(this.routeDetails);
    this.taxiService.routeDetails.next(this.routeDetails);
    this.outstationService.hideBackBtn.next(true);
    this.showEstimation = true;
  }


}
