<a routerLink="/">
    <h3>
        <img src="assets/left-arrow.png">About Us
    </h3>
</a>

<section class="ttm-row about-section clearfix">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="ttm_single_image-wrapper text-left">
                    <img class="img-fluid" src="assets/images/bg-image/col-bgimage-5.jpg" alt="single-09" height="100%" width="100%">
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-xs-12">
                <div class="padding_top20 res-991-padding_top40">
                    <!-- section title -->
                    <div class="section-title">
                        <div class="title-header">
                            <h3>WHO WE ARE</h3>
                            <h2 class="title">An Ultimate Drop Taxi provider in <b>Market since 2000</b></h2>
                        </div>
                        <div class="title-desc">
                            <p>
                                {{settings.siteName}} is an Inter-city taxi booking facilitator.We started this venture out of a personal pain point of having to pay two way charges for a one way drop trip
                            </p>

                            <p>
                                {{settings.siteName}} at the forefront of enabling mobility of customers by road during the Coronavirus pandemic. We follow careful approach to safety and precautions, and a strict compliance to the government guidelines.Our drivers undergo extensive
                                sensitization and training to ensure your safety and comfort.
                            </p>

                            <p>
                                Quality Cabs Available for You, Excellent track record of on-time service. 24/7 service to all travellers. Give droptaxi a Call Now and Our Staff Will Be Happy to Help You.


                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- row end -->
    </div>
</section>