<!-- <a routerLink="/city">
    <h3>
        <img src="assets/left-arrow.png">Book Outstation cab from {{origin}} to {{destination}}
    </h3>
</a> -->

<mat-spinner *ngIf="!hideSpinner" style="margin:0 auto;" 
mode="indeterminate" [diameter]="50" ></mat-spinner>

<ng-container *ngIf="showEstimation">
    <app-outstation></app-outstation>
</ng-container>
<div class="site-main">


    <div class="ttm-row sidebar ttm-sidebar-right clearfix">
        <div class="container">
            <!-- row -->
            <div class="row">
                <div class="col-lg-8 content-area">
                    <h4>
                        Book Outstation cab from {{origin}} to {{destination}}
                    </h4>
                    <!-- post -->
                    <article class="post ttm-blog-single clearfix">
                        <!-- post-featured-wrapper -->
                        <div class="ttm-post-featured-wrapper ttm-featured-wrapper">
                            <div class="ttm-post-featured">
                                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
                                    <ng-container *ngIf="showDirection">
                                        <agm-direction [origin]="origin" [destination]="destination" (onResponse)="onResponses($event)">
                                        </agm-direction>
                                    </ng-container>
                                </agm-map>
                            </div>
                        </div>
                        <!-- post-featured-wrapper end -->
                        <!-- ttm-blog-classic-content -->
                        <div class="ttm-blog-single-content">
                            <div class="entry-content">
                                <div class="ttm-box-desc-text">
                                    Book {{origin}} to {{destination}} cab and get best deals on online cab booking at {{settings.siteName}} . Now hire One way cab from {{origin}} to {{destination}} or get a round trip taxi from {{origin}} to {{destination}} in cheap & affordable prices.
                                    You can compare and book a taxi like Indica, Micra, Indigo, Dzire, Etios, Innova, Tempo Traveller- AC, Non AC, SUV, Sedan and luxury cars from {{origin}} to {{destination}}.
                                    <h5 class="mt-4">Hire Your Desired Cab For Your One Way Trip With {{settings.siteName}}
                                    </h5>
                                    <p>
                                        Are you looking for the cab services for your one-way trip? Then nothing is better than our {{settings.siteName}} as we are having the great options of cabs which are highly-suitable for families or friends. Hire our top-rated drivers and get a unique
                                        experience of your one-way trip along with us. Our drivers are highly skilled to drive the car in every weather condition whether it is a hilly area or the plain area. Get in touch with us and get the free price
                                        quote for your one-way trip or get the images of our luxury cars shared with you right now!
                                    </p>

                                    <h5>Book Low cost drop taxi from {{origin}} to {{destination}}
                                    </h5>

                                    <p>
                                        {{settings.siteName}} is the first choice among outstation travellers today to rent a car for weekend Getaways, Honeymoon trips, Business Meetings, Local sightseeing, Destination Holidays or any other taxi rental services you might have. We are overwhelmed
                                        with the immense love that we are receiving from all over the country and beyond for our Car Rental Services.

                                    </p>


                                </div>

                            </div>
                        </div>

                    </article>
                    <!-- post end -->
                </div>
                <div class="col-lg-4 widget-area sidebar-right">
                    <ng-container *ngFor="let city of places">

                        <ng-container *ngIf="city.city === origin">
                            <aside *ngFor="let place of city.routes" class="widget widget-search with-title">
                                <h5>
                                    {{place.origin}} to {{place.destination}}
                                </h5>

                                <img src="{{place.image}}" alt="" srcset="" style="width: 100%;">
                                <p class="mt-4">
                                    Book {{place.origin}} to {{place.destination}} taxi Service for One Way cab & Round Trip at affordable Taxi Fare at {{settings.siteName}}. Our expert Drivers will make your trip memorable
                                </p>
                                <div class="col-lg-12 text-center">
                                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor margin_top20 res-991-margin_right15" routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-cabs">BOOK NOW</a>
                                </div>
                            </aside>
                        </ng-container>
                    </ng-container>


                </div>
            </div>
            <!-- row end -->
        </div>
    </div>


</div>
